import Api from '@/axios'

export default {
  sendBulkWhatsapp (payload) {
    return Api().post('projects/whatsapp/logs/send/pluck', payload)
  },
  allWhatsappCampaigns ({ pageNumber = 1, project }) {
    return Api().get(`projects/whatsapp/campaigns?page=${pageNumber}&project_id=${project}`)
  },
  actionsCampaign (campaignId) {
    return Api().patch(`projects/whatsapp/campaigns/${campaignId}`)
  },
  sendSms (payload) {
    return Api().post('sms/send', payload)
  }
}
